import React from 'react';
import Head from 'next/head'

const HeaderTag = ({title, description, ogImage, details, url}) => {
    const escapedDescription = description?.replace( /(<([^>]+)>)/ig, '');

    if(ogImage && ogImage.startsWith("/")){
        ogImage = ogImage.substring(1);
    }
    const finalImage = process.env.NEXT_PUBLIC_IMAGE_PATH + ogImage 
    
    return (
    <Head>
        <title>{title}</title>      
        <meta description={escapedDescription} />
        
        {escapedDescription && <meta property='og:description' content={escapedDescription}></meta>}
        {url && <meta property="og:url" content={url} />}
        {title && <meta name="og:title" content={title}></meta>}
        {finalImage && <meta property='og:image' content={finalImage}></meta>}
        

        {details && <meta name="twitter:card" content="summary_large_image"></meta>}
        {finalImage && <meta property='twitter:image' content={finalImage}></meta>}
        {title && <meta name="twitter:title" content={title}></meta>}        
        {escapedDescription && <meta name="twitter:description" content={escapedDescription}></meta>}
                
    </Head>
  )
}

export default HeaderTag;
