import sendRequest from './sendRequest';

const BASE_PATH = 'api';

export const getCountries = data =>
    sendRequest(`${BASE_PATH}/Common/GetAllCountries`, {
        method: 'GET',
    });

export const getAllProvinces = data =>
    sendRequest(`${BASE_PATH}/Common/GetAllProvinces?countryId=${data ? data : ''}`, {
        method: 'GET',
    });

export const contactUs = data =>
    sendRequest(`${BASE_PATH}/Customer/Contact`, {
        body: JSON.stringify(data)
    });

export const customerRegisterRequest = data =>
    sendRequest(`${BASE_PATH}/Customer/RegisterRequest`, {
        body: JSON.stringify(data)
    });

export const foreignRights = data =>
    sendRequest(`${BASE_PATH}/Products/GetProductsForeignRights?${data}`, {
        method: 'GET',
    });
export const getPublishingLineByName = data =>
    sendRequest(`${BASE_PATH}/PublishingLines/GetPublishingLineByName/`, {
        body: JSON.stringify(data),
    });
export const getCategoriesByPublishingLine = data =>
    sendRequest(`${BASE_PATH}/Categories/GetCategoriesByPublishingLine?publishingLineId=${data}`, {
        method: 'GET',
    });
export const getCollectionsbyCategory = data =>
    sendRequest(`${BASE_PATH}/Collections/GetCollectionsbyCategory?categoryId=${data}`, {
        method: 'GET',
    });
export const getProductsListingPage = (data, url) =>{        
    return sendRequest(`${BASE_PATH}/Products/GetProductsListingPage?requestUrl=${data.url}`, {
        body: JSON.stringify(data), url
    });
}    
export const subscribeNewsLetter = (data, url) =>
    sendRequest(`${BASE_PATH}/Newsletter/subscribeRequest`, {
        body: JSON.stringify(data), url
    });
export const activateNewsletter = (data, url) =>
    sendRequest(`${BASE_PATH}/Newsletter/activateSubscription`, {
        body: JSON.stringify(data), url
    });
export const getBiblioteca = data =>
    sendRequest(`${BASE_PATH}/Products/GetProductsLibraryList?${data}`, {
        method: 'GET',
    });
export const getProductsMerchandiseNew = data =>
      sendRequest(`${BASE_PATH}/Products/GetProductsMerchandiseNew`, {
        method: 'GET',
    });
export const getMerchandiseBrands = data =>
      sendRequest(`${BASE_PATH}/Products/GetMerchandiseBrands`, {
        method: 'GET',
    });
export const getMerchandiseCharacters = data =>
      sendRequest(`${BASE_PATH}/Products/GetMerchandiseCharacters`, {
        method: 'GET',
    });
export const getMerchandiseProductTypes = data =>
      sendRequest(`${BASE_PATH}/Products/GetMerchandiseProductTypes`, {
        method: 'GET',
    });

export const getWebTexts = (data, url) =>{   
    return sendRequest(`${BASE_PATH}/WebTexts/GetTexts?WebPage=${data.WebPages}`, {
        method: 'POST', url
    });
}
export const getWebPage = data =>
    sendRequest(`${BASE_PATH}/WebPage/GetWebs`, {
    method: 'GET',
});

export const getShopPage = data =>
    sendRequest(`${BASE_PATH}/Shops/GetPhysicalStores`, {
    method: 'GET',
});

export const getHelpPage = data =>
    sendRequest(`${BASE_PATH}/HelpPage/GetHelpPage`, {
    method: 'GET',
});

export const GetForeignRightsPage = data =>
    sendRequest(`${BASE_PATH}/MiniSite/GetForeignRightsPage`, {
    method: 'GET',
});
